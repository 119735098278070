#name {
    font-size: 60px;
    font-weight: bold;
    position: sticky;
    margin-left: auto;
    margin-right: auto;
}
#homeMain {
    text-align: center;
    font-family: 'Times New Roman', Times, serif;
    border-left-style: solid;
    border-right-style: solid;
    border-color: rbg(42, 27, 61);
    background-color: rgb(213, 213, 213);
}
#body {
    --color1:#3a3b3d;
    --color2:#2f2e31;
    background-image: repeating-linear-gradient(
  45deg,
  var(--color1),
  var(--color1) 25px,
  var(--color2) 25px,
  var(--color2) 50px
);
height: 100vh; 
background-attachment: fixed;
background-repeat: no-repeat;
min-height: 100%;
background-size: cover;
}
#pages {
    list-style-type: none;
    list-style-position: outside;
    text-decoration: none;
    display: inline;
    margin: 0;
    padding: 0;
}
.page {
    display: inline;
    text-align: center;
    margin: 15px;
}
a {
    color: black;
    text-decoration: none;
}
a:hover {
    color: #870606;
    text-decoration: underline;
}
#aboutText {
    text-align: center;
}
#resume {
    justify-content: center;
    width: 90%;
    height: 82%;
    margin-top: 15px;
    margin-left: auto;
    margin-right: auto;
}
#bascom {
    width: 100%;
}
#projectContainer{
    margin-top: 10px;
}
.card {
    margin-top: 10px;
}
#className:hover {
    color: #870606;
    text-decoration: underline;
}
#projectImg{
    border-radius: 5%;
    max-width:75%;
    margin-left: auto;
    margin-right: auto;
}
#projectPage {
    margin-top:10px;
}
#titleCard {
    display: flex;
    position: relative;
    justify-content: center;
    flex-direction: row;
    align-items: center;
}
#icon {
    vertical-align:middle;
    position: static;
    margin-top: 1%;
}
#icon:hover {
    color:blue;
}
#icons {
    justify-content: right;
    position: absolute;
    right:0;
}